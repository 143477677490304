import Layout from "../layout";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, IconButton,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import {StyledTableCell} from "../../../components/styled/Table";
import React, {type SyntheticEvent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {getCookieByName} from "../../../utils/CookieUtil";
import axios from "axios";
import {useSnackbar} from "notistack";
import moment from "moment";
import {confirmAlert} from "react-confirm-alert";
import {RecordEditModal} from "../../../components/modals/record/recordEditModal";
import type {IRecord} from "../../../types/types";
import RecordViewModal from "../../../components/modals/record/recordViewModal";
import ShareRecordDialog from "../../../components/modals/record/shareRecordDialog";
import RecordEditVideoModal from "../../../components/modals/record/recordEditVideoModal";
import "./index.css";
import Loading from "../../../components/loading/Loading";
import {handleErrorMessage, secondsToMs} from "../../../utils/utils";
import {HtmlTooltip} from "../../../components/icon/icon";

export function Record () {
    const { t } = useTranslation();
    const userId = getCookieByName("u") || "";
    const authToken = getCookieByName("a") || "";
    const [recordList, setRecordList] = useState<IRecord[]>([]);
    const {enqueueSnackbar} = useSnackbar();
    const [isOpenRecordEditModal, setOpenRecordEditModal] = useState<boolean>(false);
    const [recordUpdate, setRecordUpdate] = useState<IRecord>();
    const [isOpenRecordViewModal, setOpenRecordViewModal] = useState<boolean>(false);
    const [isOpenShareRecordDialog, setOpenShareRecordDialog] = useState<boolean>(false);
    const [videoUrlView, setVideoUrlView] = useState<string>();
    const [videoNameView, setVideoNameView] = useState<string>();
    const WEBM_EXTENSION = ".webm";
    const WEBA_EXTENSION = ".weba";
    const [isOpenRecordEditVideoModal, setOpenRecordEditVideoModal] = useState<boolean>(false);

    useEffect(() => {
        updateRecordList();
    }, []);

    const updateRecordList = () => {
        getRecordList().then((recordsResponse) => {
            const records = recordsResponse
                .map(record => {
                    if (record.video_file && record.audio_file) {
                        record.video_file_name = record.video_file
                            .replace(`${record.account_id}/`, "")
                            .replace(WEBM_EXTENSION, "");
                        record.audio_file_name = record.audio_file
                            .replace(`${record.account_id}/`, "")
                            .replace(WEBA_EXTENSION, "");
                    }
                    if (record.edited_video_file) {
                        record.edited_video_file_name = record.edited_video_file
                            .replace(`${record.account_id}/`, "")
                            .replace(WEBM_EXTENSION, "");
                    }
                    return record;
                });
            setRecordList(records);
        }).catch(() => {
            enqueueSnackbar(t("Error call list record"), {variant: "error"});
        });
    };

    const getRecordList = async () => {
        const records = [] as IRecord[];
        const response = await axios.get(`${process.env.REACT_APP_SESSION_API || ""}/record?u=${userId || ""}&a=${authToken || ""}`);
        response.data.record.forEach((record: IRecord) => {
            records.push(record);
        });
        return records;
    };

    const handleCreateSubtitles = (event: React.MouseEvent<HTMLButtonElement>, record: IRecord) => {
        const button = event.target as HTMLButtonElement;
        button.disabled = true;
        const params = {
            record_id: record.id
        };
        axios.post(`${process.env.REACT_APP_PORTAL_API || ""}/webrtc/transcriptions?u=${userId}&a=${authToken}`, params)
            .then(response => {
                enqueueSnackbar("Success", { variant: "success" });
                updateRecordList();
            }).catch(err => {
                button.disabled = false;
                if (err.response.data.message) {
                    enqueueSnackbar(err.response.data.message, { variant: "error" });
                } else {
                    enqueueSnackbar("Error", { variant: "error" });
                }
            });
    };

    const confirmDeleteRecord = (recordId: string) => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <Dialog open={true} className="customized-dialog">
                        <DialogTitle>{t("confirmation")}</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                {t("Do you want to delete the recording file?")}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => { onClose(); }}>{t("cancel")}</Button>
                            <Button onClick={() => { handleDeleteRecord(recordId); onClose(); }}>{t("delete")}</Button>
                        </DialogActions>
                    </Dialog>
                );
            }
        });
    };

    const handleDeleteRecord = (recordId: string) => {
        axios.delete(`${process.env.REACT_APP_SESSION_API || ""}/record?u=${userId}&a=${authToken}&record_id=${recordId}`)
            .then(() => {
                updateRecordList();
            })
            .catch(err => {
                handleErrorMessage(err, enqueueSnackbar);
            });
    };

    const handleOpenModalEditRecord = (record: IRecord) => {
        setRecordUpdate(record);
        setOpenRecordEditModal(true);
    };

    const handleCloseModalEditRecord = () => {
        setRecordUpdate(undefined);
        setOpenRecordEditModal(false);
    };

    const handleUpdateRecord = (record: IRecord, videoFileName: string, audioFileName: string) => {
        const videoFile = `${record.account_id}/${videoFileName}${WEBM_EXTENSION}`;
        const audioFile = `${record.account_id}/${audioFileName}${WEBA_EXTENSION}`;
        const params = {
            record_id: record.id,
            video_file: videoFile,
            audio_file: audioFile
        };
        axios.put(`${process.env.REACT_APP_SESSION_API || ""}/record?u=${userId}&a=${authToken}`, params)
            .then(() => {
                updateRecordList();
                setOpenRecordEditModal(false);
            })
            .catch(err => {
                handleErrorMessage(err, enqueueSnackbar);
            });
    };

    const handleOpenModalViewRecord = (event: React.MouseEvent, videoUrl: string, videoName: string) => {
        event.preventDefault();
        setVideoUrlView(videoUrl);
        setVideoNameView(videoName);
        setOpenRecordViewModal(true);
    };

    const handleCloseModalViewRecord = () => {
        setVideoUrlView(undefined);
        setVideoNameView(undefined);
        setOpenRecordViewModal(false);
    };

    const handleOpenShareRecordDialog = (record: IRecord) => {
        setRecordUpdate(record);
        setOpenShareRecordDialog(true);
    };

    const handleCloseShareRecordDialog = () => {
        setRecordUpdate(undefined);
        setOpenShareRecordDialog(false);
    };

    const isShared = (accountId: string) => {
        return accountId !== userId;
    };

    const handleCreateSummary = (event: React.MouseEvent<HTMLButtonElement>, recordId: string) => {
        const button = event.target as HTMLButtonElement;
        button.disabled = true;
        button.classList.add("Mui-disabled");
        const params = {
            record_id: recordId
        };
        const recordListUpdate = recordList.map(record => {
            if (record.id === recordId) {
                record.is_create_summary = true;
            }
            return record;
        });
        setRecordList(recordListUpdate);
        axios.post(`${process.env.REACT_APP_PORTAL_API || ""}/webrtc/record_summary?u=${userId}&a=${authToken}`, params)
            .then(response => {
                enqueueSnackbar("Success", {variant: "success"});
                updateRecordList();
            })
            .catch(err => {
                if (err.response.data.message) {
                    enqueueSnackbar(err.response.data.message, {variant: "error"});
                } else {
                    enqueueSnackbar("Error", {variant: "error"});
                }
            })
            .finally(() => {
                button.disabled = false;
                button.classList.remove("Mui-disabled");
            });
    };

    const handleShowSummary = (summary: string) => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <Dialog open={true} className="customized-dialog">
                        <DialogTitle>{t("Summary")}</DialogTitle>
                        <DialogContent>
                            <div className="record-summary" dangerouslySetInnerHTML={{__html: summary}}></div>
                        </DialogContent>
                        <DialogActions>
                        <Button onClick={() => { onClose(); }}>{t("Close")}</Button>
                        </DialogActions>
                    </Dialog>
                );
            }
        });
    };

    const handleOpenRecordEditVideo = (event: React.MouseEvent, record: IRecord) => {
        event.preventDefault();
        setRecordUpdate(record);
        setVideoUrlView(record.video_url);
        setVideoNameView(record.video_file_name);
        setOpenRecordEditVideoModal(true);
    };

    const handleCloseRecordEditVideo = () => {
        setRecordUpdate(undefined);
        setVideoUrlView(undefined);
        setVideoNameView(undefined);
        setOpenRecordEditVideoModal(false);
    };

    const urlViewRecordingFile = (key: string) => {
        return `${process.env.REACT_APP_PORTAL_API || ""}/webrtc/download-record?u=${userId || ""}&a=${authToken || ""}&key=${key}`;
    };

    const handleDownloadSubtitles = (transcriptionsFile: string) => {
        window.location.href = `${process.env.REACT_APP_PORTAL_API || ""}/webrtc/download_transcriptions?u=${userId}&a=${authToken}&key=${transcriptionsFile}`;
    };

    const handleLoadedMetadata = (event: SyntheticEvent<HTMLVideoElement>, recordId: string) => {
        const video = event.target as HTMLVideoElement;
        const duration = Math.floor(video.duration);
        const recordListUpdate = recordList.map(record => {
            if (record.id === recordId) {
                record.recording_duration = duration;
            }
            return record;
        });
        setRecordList(recordListUpdate);
    };

    const handleShowTranscription = (recordId: string, transcriptionsFile: string) => {
        const url = `${process.env.REACT_APP_SESSION_API || ""}/transcriptions?u=${userId}&a=${authToken}&record_id=${recordId}`;
        axios.get(url).then(response => {
            const transcriptions = response.data?.transcriptions;
            confirmAlert({
                customUI: ({ onClose }) => {
                    return (
                        <Dialog open={true} className="customized-dialog">
                            <DialogTitle>{t("Transcription")}</DialogTitle>
                            <DialogContent>
                                <div className="record-transcriptions">
                                    {transcriptions}
                                </div>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => { handleDownloadSubtitles(transcriptionsFile); }}>{t("Download")}</Button>
                                <Button onClick={() => { onClose(); }}>{t("Close")}</Button>
                            </DialogActions>
                        </Dialog>
                    );
                }
            });
        }).catch(err => {
            handleErrorMessage(err, enqueueSnackbar);
        });
    };

    const handleStopTranscription = (recordId: string) => {
        const url = `${process.env.REACT_APP_SESSION_API || ""}/stop_transcriptions?u=${userId}&a=${authToken}&record_id=${recordId}`;
        axios.put(url).then(response => {
            updateRecordList();
        }).catch(err => {
            handleErrorMessage(err, enqueueSnackbar);
        });
    };

    return <Layout>
        <div style={{minWidth: "768px"}}>
            <TableContainer>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell align="center">{t("Recording file")}</StyledTableCell>
                            <StyledTableCell align="center">{t("room name")}</StyledTableCell>
                            <StyledTableCell align="center">{t("Recording date")}</StyledTableCell>
                            <StyledTableCell align="center">{t("Recording time")}</StyledTableCell>
                            <StyledTableCell align="center">{t("Audio file")}</StyledTableCell>
                            <StyledTableCell align="center">{t("Transcription")}</StyledTableCell>
                            <StyledTableCell align="center">{t("AI Summary")}</StyledTableCell>
                            <StyledTableCell align="center">{t("share")}</StyledTableCell>
                            <StyledTableCell align="center">{t("Operation")}</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            recordList.map((record, index) => {
                                return <TableRow key={index} sx={{"&:last-child th": {border: 0}}}>
                                    <StyledTableCell style={{ width: "15%", wordBreak: "break-word" }}>
                                        {
                                            record.progress_rate !== 100
                                                ? <>
                                                    <Loading width="40px" height="40px" />
                                                    <span className="record-progressing">{`処理中 (${record.progress_rate}%)`}</span>
                                                </>
                                                : <>
                                                    {
                                                        record.edited_video_file &&
                                                        <>
                                                            <a href="#" onClick={(event) => {
                                                                handleOpenModalViewRecord(event, urlViewRecordingFile(record.edited_video_file), record.video_file_name);
                                                            }}>
                                                                {record.video_file_name && `${record.video_file_name}${WEBM_EXTENSION}`}
                                                            </a>
                                                            <br/>
                                                        </>
                                                    }
                                                    <a href="#" onClick={(event) => { handleOpenModalViewRecord(event, urlViewRecordingFile(record.video_file), record.video_file_name); }}>
                                                        {record.edited_video_file ? t("Original file") : `${record.video_file_name && `${record.video_file_name}${WEBM_EXTENSION}`}`}
                                                    </a>
                                                    {
                                                        !isShared(record.account_id) &&
                                                        <IconButton color="secondary" onClick={(event) => {
                                                            handleOpenRecordEditVideo(event, record);
                                                        }} disabled={record.progress_rate !== 100 || isShared(record.account_id)}>
                                                            <img className="ac-icon-record" src="/img/movie_filter_40dp.svg" alt="article"/>
                                                        </IconButton>
                                                    }
                                                </>
                                        }
                                    </StyledTableCell>
                                    <StyledTableCell style={{width: "15%", wordBreak: "break-word" }}>{record.channel_name}</StyledTableCell>
                                    <StyledTableCell align="center">{moment(record.create_date).format("YYYY/MM/DD HH:mm")}</StyledTableCell>
                                    <StyledTableCell align="center">
                                        {
                                            record.recording_duration ? secondsToMs(record.recording_duration) : "-"
                                        }
                                        <video onLoadedMetadata={(event) => { handleLoadedMetadata(event, record.id); }} hidden={true} src={urlViewRecordingFile(record.audio_file)}></video>
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                        {
                                            record.audio_file
                                                ? <IconButton onClick={(event) => { handleOpenModalViewRecord(event, urlViewRecordingFile(record.audio_file), record.audio_file_name); }}>
                                                    <img className="ac-icon-record" src="/img/ac-mic-record.svg" alt="mic"/>
                                                </IconButton>
                                                : <>-</>
                                        }
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                        {
                                            record.is_transcribing
                                                ? <div style={{ display: "flex", justifyContent: "center" }}>
                                                    <Loading width="40px" height="40px" />
                                                    <span style={{ paddingTop: 2 }}>
                                                        <HtmlTooltip title={t("Stop transcription")}>
                                                            <IconButton onClick={() => { handleStopTranscription(record.id); }}>
                                                            <img className="ac-icon-record" src="/img/stop_24dp_F25A5A.svg" alt="mic"/>
                                                        </IconButton>
                                                        </HtmlTooltip>
                                                    </span>
                                                </div>
                                                : <>
                                                    {
                                                        record.transcriptions_file
                                                            ? <IconButton onClick={() => {
                                                                handleShowTranscription(record.id, record.transcriptions_file);
                                                            }}>
                                                                <img className="ac-icon-record" src="/img/article_20dp.svg" alt="mic"/>
                                                            </IconButton>
                                                            : <Button className="btn-create-subtitles" size="small"
                                                                      onClick={(event) => {
                                                                          handleCreateSubtitles(event, record);
                                                                      }}
                                                                      disabled={record.is_transcribing || !record.audio_file || isShared(record.account_id)}
                                                            >{t("Generate")}</Button>
                                                    }
                                                </>
                                        }
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                        {
                                            record.is_create_summary
                                                ? <Loading width="40px" height="40px" />
                                                : <>
                                                    {
                                                        record.summary
                                                            ? <IconButton onClick={() => {
                                                                handleShowSummary(record.summary);
                                                            }}>
                                                                <img className="ac-icon-record" src="/img/article_20dp.svg" alt="article"/>
                                                            </IconButton>
                                                            : <Button disabled={!record.transcriptions_file} size="small" onClick={(event) => {
                                                                handleCreateSummary(event, record.id);
                                                            }}>{t("Generate")}</Button>
                                                    }
                                                </>
                                        }
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                        <Button color="primary" onClick={() => { handleOpenShareRecordDialog(record); }}
                                                disabled={record.progress_rate !== 100 || isShared(record.account_id)}>
                                            {record.shared_account.length > 0 ? `${t("Sharing") as string}(${record.shared_account.length})` : t("share")}
                                        </Button>
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <IconButton color="warning" onClick={() => {
                                            handleOpenModalEditRecord(record);
                                        }}
                                                disabled={record.progress_rate !== 100 || isShared(record.account_id)}>
                                            <img className="ac-icon-record" src="/img/ac-pen-outline.svg"
                                                 alt="article"/>
                                        </IconButton>
                                        <IconButton color="error" onClick={() => {
                                            confirmDeleteRecord(record.id);
                                        }}
                                                disabled={record.is_transcribing || isShared(record.account_id)}>
                                            <img className="ac-icon-record" src="/img/ac-garbagecan-outline.svg" alt="article"/>
                                        </IconButton>
                                    </StyledTableCell>
                                </TableRow>;
                            })
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            <Typography
                sx={{
                    color: "#666666",
                    paddingTop: "20px",
                    paddingBottom: "30px"
                }}
                >{t("It will take some time until the recording file can be downloaded. Please wait.")}
            </Typography>
        </div>
        <RecordEditModal
            open={isOpenRecordEditModal}
            handleClose={handleCloseModalEditRecord}
            record={recordUpdate}
            handleUpdateRecord={handleUpdateRecord}/>
        <RecordViewModal
            open={isOpenRecordViewModal}
            handleClose={handleCloseModalViewRecord}
            videoUrl={videoUrlView}
            videoName={videoNameView}/>
        <ShareRecordDialog
            open={isOpenShareRecordDialog}
            handleClose={handleCloseShareRecordDialog}
            updateRecordList={updateRecordList}
            record={recordUpdate}/>
        <RecordEditVideoModal
            open={isOpenRecordEditVideoModal}
            handleClose={handleCloseRecordEditVideo}
            videoUrl={videoUrlView}
            videoName={videoNameView}
            record={recordUpdate}
            updateRecordList={updateRecordList} />
    </Layout>;
}
