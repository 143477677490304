import "./fearures.css";
import Icon from "../icon/icon";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import Brightness1Icon from "@mui/icons-material/Brightness1";
import {useDispatch, useSelector} from "react-redux";
import {
    getStartedRecording,
    ParticlesType,
    setCameraOn,
    setFirstLogin,
    setIsOpenErrorDevices,
    setIsSeenMessage,
    setMicroOn,
    setOpenErrorCameraDialog,
    setOpenErrorMicDialog,
    setParticles,
    setRecording,
    setShowBreakoutRoom,
    setShowChat,
    setSHowParticipantList,
    setShowRoomDetailModal
} from "../../services/sora/soraSlice";
import {CAMERA_ID, IS_CAMERA, IS_MIC, MICRO_ID, RECVONLY, SPEAKER_ID} from "../../constants/constant";
import DeviceModal from "../modals/device/deviceModal";
import {setShowVirtualBackgroundModal} from "../../services/virtualBackground/virtualBackgroundSlice";
import {MenuItem, Popover} from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import StyledMenu from "../styled/StyledMenu";
import DeviceErrorModal from "../modals/device/deviceErrorModal";
import {ParticlesContainer} from "../particlesContainer/particlesContainer";
import {FireworksParticles} from "../particlesContainer/fireworksParticles";
import axios from "axios";
import {useSnackbar} from "notistack";
import AcScreenSharing from "../icon/AcScreenSharing";
import AcChat from "../icon/AcChat";
import {AcMemberOutline} from "../icon/AcMemberOutline";
import AcStarsOutline from "../icon/AcStarsOutline";
import {useTranslation} from "react-i18next";
import React, {forwardRef, type Ref, useEffect, useImperativeHandle, useState} from "react";
import {type AppDispatch, type RootState} from "../../store";
import {type Device, setCameraUsing, setMicUsing, setSpeakerUsing} from "../../services/devices/devicesSlice";
import RoomDetailModal from "../modals/roomDetail/roomDetailModal";
import {getCookieByName} from "../../utils/CookieUtil";
import type {StartRecordingResponse} from "../../types/types";
import RecordModal from "../modals/record/recordModal";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import SubRoomIcon from "../icon/SubRoomIcon";
import SettingQuickly from "../modals/device/settingQuickly";
import EmojiContainer from "../emojiContainer/emojiContainer";
import {emojiList, type IEmoji} from "../chat/emoji";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";
import SubTitleIcon from "../icon/SubTitleIcon";
import SubTitleDialog from "../subTitle/subTitleDialog";

interface FeaturesProps {
    callEnd?: any
    shareScreen?: any
    stopShare?: any
    stopDevice?: any
    handleClickMicIcon: any
    handleClickCameraIcon: any
    handleClickOpenErrorDevice: any
    handleClickCloseErrorDevice: any
    sendMessageRecording: any
    sendMessageParticles: any
}

export interface FeaturesRef {
    handleChangeCamera: (event: React.ChangeEvent<HTMLSelectElement>) => void
    handleChangeMic: (event: React.ChangeEvent<HTMLSelectElement>) => void
    handleChangeSpeaker: (event: React.ChangeEvent<HTMLSelectElement>) => void
}

const Features = forwardRef((props: FeaturesProps, ref: Ref<FeaturesRef>) => {
    const {
        isMeeting,
        isCameraOn,
        isMicroOn,
        isMineShare,
        isShowChat,
        isSHowParticipantList,
        isShowBreakoutRoom,
        isSubTitle,
        isOpenErrorDevices,
        isRecording,
        particles,
        connectType,
        roomName,
        isSeenMessage,
        isShowRoomDetailModal,
        breakoutRoom,
        isMeetingOwner,
        remoteConnection,
        isErrorCamera,
        isErrorMic
    } = useSelector((state: RootState) => state.sora);
    const [openSettingDevices, setOpenSettingDevices] = React.useState(false);
    const {cameras, micros, speakers, deviceUsing, isLoading} = useSelector((state: RootState) => state.devices);
    const { enqueueSnackbar } = useSnackbar();
    const {isShowVirtualBackgroundModal} = useSelector((state: RootState) => state.virtualBackground);
    const [isExpandMore, setExpandMore] = useState<boolean>(true);
    useImperativeHandle(ref, () => ({ handleChangeCamera, handleChangeMic, handleChangeSpeaker }));
    const { t } = useTranslation();
    const userId = getCookieByName("u") || "";
    const authToken = getCookieByName("a") || "";
    const [isShowRecordModal, setShowRecordModal] = useState<boolean>(false);
    const [isShowSettingMicQuickly, setIsShowSettingMicQuickly] = useState<boolean>(false);
    const [isShowSettingCameraQuickly, setIsShowSettingCameraQuickly] = useState<boolean>(false);
    const [anchorEmojiEl, setAnchorEmojiEl] = React.useState<HTMLElement | null>(null);
    const openAnchorEmojiEl = Boolean(anchorEmojiEl);
    const [isShowSubTitleDialog, setShowSubTitleDialog] = useState<boolean>(false);

    useEffect(() => {
        dispatch(setCameraOn(cameras.length > 0 && localStorage.getItem(IS_CAMERA) !== "false" && connectType !== RECVONLY));
        dispatch(setMicroOn(micros.length > 0 && localStorage.getItem(IS_MIC) !== "false" && connectType !== RECVONLY));
    }, [cameras, micros]);

    useEffect(() => {
        dispatch(getStartedRecording()).then(null).catch(null);
    }, [breakoutRoom, isMeeting]);

    const handleOpenSettingDevices = () => {
        handleCloseBtnOption();
        setOpenSettingDevices(true);

        setIsShowSettingMicQuickly(false);
        setIsShowSettingCameraQuickly(false);
    };
    const handleCloseSettingDevices = () => {
        setOpenSettingDevices(false);
    };

    const defaultCamera = deviceUsing.camera != null ? deviceUsing.camera.deviceId : "";
    const defaultMic = deviceUsing.micro != null ? deviceUsing.micro.deviceId : "";
    const defaultSpeaker = deviceUsing.speaker != null ? deviceUsing.speaker.deviceId : "";

    const dispatch = useDispatch<AppDispatch>();
    const [selectedValueCamera, setSelectedValueCamera] = useState(defaultCamera);
    const [selectedValueMic, setSelectedValueMic] = useState(defaultMic);
    const [selectedValueSpeaker, setSelectedValueSpeaker] = useState(defaultSpeaker);

    const handleChangeCamera = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const value = event.target.value;

        const selectedCamera = cameras.find(camera => camera.deviceId === value);
        const label = selectedCamera ? selectedCamera.label : "";

        setSelectedValueCamera(value);
        const device: Device = {
            deviceId: value,
            label
        };
        dispatch(setCameraUsing(device));
        localStorage.setItem(CAMERA_ID, value);
    };

    const handleChangeMic = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const value = event.target.value;
        const selectedMicro = micros.find(micro => micro.deviceId === value);
        const label = selectedMicro ? selectedMicro.label : "";

        setSelectedValueMic(value);
        const device: Device = {
            deviceId: value,
            label
        };
        dispatch(setMicUsing(device));
        localStorage.setItem(MICRO_ID, value);
    };

    const handleChangeSpeaker = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const value = event.target.value;
        const selectedSpeaker = speakers.find(speaker => speaker.deviceId === value);
        const label = selectedSpeaker ? selectedSpeaker.label : "";

        setSelectedValueSpeaker(value);
        const device: Device = {
            deviceId: value,
            label
        };
        dispatch(setSpeakerUsing(device));
        localStorage.setItem(SPEAKER_ID, value);
    };

    // Completed later

    const handleRecordClick = () => {
        if (breakoutRoom?.channelId) {
            if (isRecording) {
                const params = { channel_id: breakoutRoom.channelId };
                axios.post(`${process.env.REACT_APP_WEBRTC_API || ""}`, params, {
                    headers: {
                        "x-sora-target": "Sora_20231220.StopRecording"
                    }
                }).then(() => {
                    dispatch(setRecording(false));
                    props.sendMessageRecording(false);
                }).catch(() => {
                    enqueueSnackbar(t("Error call StopRecording"), { variant: "error" });
                });
            } else {
                setShowRecordModal(true);
                setBtnOptionAnchorEl(null);
            }
        }
    };

    const startRecording = (accountId: string, autoGenerateSummary: boolean) => {
        if (!breakoutRoom) {
            return;
        }
        const params = {
            channel_id: breakoutRoom.channelId,
            metadata: {
                auto_generate_summary: autoGenerateSummary,
                user_id: userId,
                auth_token: authToken
            }
        };
        axios.post<StartRecordingResponse>(`${process.env.REACT_APP_WEBRTC_API || ""}`, params, {
            headers: {
                "x-sora-target": "Sora_20231220.StartRecording"
            }
        }).then((res) => {
            dispatch(setRecording(true));
            props.sendMessageRecording(true);
            const params = {
                channel_id: breakoutRoom.channelId,
                account_id: accountId || userId,
                record_id: res.data.recording_id,
                session_id: res.data.session_id,
                channel_name: roomName
            };
            axios.post<StartRecordingResponse>(`${process.env.REACT_APP_SESSION_API || ""}/record?u=${userId}&a=${authToken}`, params)
                .then(null)
                .catch(() => {
                    enqueueSnackbar(t("Error call StartRecording"), { variant: "error" });
                });
        }).catch(() => {
            enqueueSnackbar(t("Error call StartRecording"), { variant: "error" });
        });
    };

    const [btnOptionAnchorEl, setBtnOptionAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(btnOptionAnchorEl);
    const handleClickBtnOption = (event: React.MouseEvent<HTMLElement>) => {
        setBtnOptionAnchorEl(event.currentTarget);
    };
    const handleCloseBtnOption = () => {
        setBtnOptionAnchorEl(null);
    };
    const handleClickParticles = (particlesType: ParticlesType) => {
        const name = remoteConnection.find(connection => connection.isMine)?.realName || "";
        const isShow = particles.type !== particlesType;
        if (!isShow) {
            particlesType = ParticlesType.NONE;
        }
        const particle = {
            isShow,
            type: particlesType,
            name
        };
        dispatch(setParticles(particle));
        props.sendMessageParticles(particle);
    };

    const handleShowChat = () => {
        if (!isSeenMessage && !isShowChat) {
            dispatch(setIsSeenMessage(true));
        }
        dispatch(setShowChat(!isShowChat));
    };

    useEffect(() => {
        let timeoutParticle: any;
        if (particles.isShow) {
            timeoutParticle = setTimeout(() => {
                const particle = {
                    isShow: false,
                    type: ParticlesType.NONE
                };
                dispatch(setParticles(particle));
            }, 5000);
        }

        return () => {
            clearTimeout(timeoutParticle);
        };
    }, [particles]);

    useEffect(() => {
        if (isLoading && !isMeeting) {
            dispatch(setIsOpenErrorDevices(cameras.length === 0 || micros.length === 0));
        }
    }, [isLoading]);

    const handleOpenRoomDetailModal = () => {
        setBtnOptionAnchorEl(null);
        dispatch(setShowRoomDetailModal(true));
    };

    const handleCloseRoomDetailModal = () => {
        dispatch(setShowRoomDetailModal(false));
    };

    const handleShowSettingMicQuickly = () => {
        if (micros.length > 0) {
            if (!isShowSettingMicQuickly) {
                setIsShowSettingCameraQuickly(false);
            }
            setIsShowSettingMicQuickly(!isShowSettingMicQuickly);
        } else {
            props.handleClickOpenErrorDevice();
        }
    };

    const handleShowSettingCameraQuickly = () => {
        if (cameras.length > 0) {
            if (!isShowSettingCameraQuickly) {
                setIsShowSettingMicQuickly(false);
            }
            setIsShowSettingCameraQuickly(!isShowSettingCameraQuickly);
        } else {
            props.handleClickOpenErrorDevice();
        }
    };

    const handleCloseSettingQuickly = () => {
        setIsShowSettingMicQuickly(false);
        setIsShowSettingCameraQuickly(false);
    };

    useEffect(() => {
        setIsShowSettingMicQuickly(false);
        setIsShowSettingCameraQuickly(false);
    }, [micros, cameras]);

    const handleClickEmojiEl = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEmojiEl(event.currentTarget);
    };

    const handleCloseEmojiEl = () => {
        setAnchorEmojiEl(null);
    };

    const handleClickEmoji = (emoji: IEmoji) => {
        switch (emoji.name) {
            case "fireworks":
                handleClickParticles(ParticlesType.FIREWORKS);
                break;
            case "snow":
                handleClickParticles(ParticlesType.SNOW);
                break;
            default: {
                const name = remoteConnection.find(connection => connection.isMine)?.realName || "";
                const particle = {
                    isShow: true,
                    type: ParticlesType.EMOJI,
                    emojiType: emoji.name,
                    name
                };
                dispatch(setParticles(particle));
                props.sendMessageParticles(particle);
            }
        }
    };

    const handleOpenErrorCameraDialog = () => {
        dispatch(setOpenErrorCameraDialog(true));
    };

    const handleOpenErrorMicDialog = () => {
        dispatch(setOpenErrorMicDialog(true));
    };

    const handleOpenSubTitleDialog = () => {
        setShowSubTitleDialog(true);
        setBtnOptionAnchorEl(null);
    };

    const handleCLoseSubTitleDialog = () => {
        setShowSubTitleDialog(false);
    };

    return (
        <>
            <div className={`container-features ${isExpandMore ? "is-expand-more" : "is-expand-close"}`}>
                <span className="btn-expand" onClick={() => { setExpandMore(!isExpandMore); dispatch(setFirstLogin(false)); }}>
                    {
                        isExpandMore
                            ? <img src="/img/ci-arrow-down.svg" alt="ExpandMoreIcon" className="btn-expand-less"/>
                            : <img src="/img/ci-arrow-up.svg" alt="ExpandMoreIcon" className="btn-expand-less"/>
                    }
                </span>
                <div className="features-title">
                    <span>
                        {
                            isRecording &&
                            <Brightness1Icon className={`span-icon-record ${isRecording ? "icon-recording" : "icon-not-recording"}`}/>
                        }
                        {
                            !isRecording &&
                            <Brightness1Icon className={`span-icon-record ${isRecording ? "icon-recording" : "icon-not-recording"}`}/>
                        }
                    </span>
                    <span className="room-name">{roomName}</span>
                </div>
                <div className="basic-features">
                    {
                        connectType !== RECVONLY &&
                        <div className="container-setting-feature">
                            <div className="setting-feature">
                                {
                                    isShowSettingMicQuickly
                                        ? <Icon titleTooltip={t("sound-setting-mode")} classCustom="bg-red"
                                                handleOnclick={handleShowSettingMicQuickly}>
                                            <img src="/img/ci-arrow-down.svg" alt="mic"/>
                                        </Icon>
                                        : <Icon titleTooltip={t("sound-setting-mode")} classCustom="bg-red"
                                                handleOnclick={handleShowSettingMicQuickly}>
                                            <img src="/img/ci-arrow-up.svg" alt="mic"/>
                                        </Icon>
                                }
                                <SettingQuickly hiddenMic={!isShowSettingMicQuickly}
                                                hiddenCamera={!isShowSettingCameraQuickly}
                                                hiddenSpeaker={!isShowSettingMicQuickly}
                                                handleChangeMic={handleChangeMic}
                                                handleChangeCamera={handleChangeCamera}
                                                handleChangeSpeaker={handleChangeSpeaker}
                                                handleOpenSettingDevices={handleOpenSettingDevices}/>
                            </div>
                            <div>
                                {
                                    micros.length === 0 || isErrorMic
                                        ? <Icon handleOnclick={isErrorMic ? handleOpenErrorMicDialog : props.handleClickOpenErrorDevice}
                                                titleTooltip="Show more info" classCustom="bg-red">
                                            <img src="/img/ac-mic-mute.svg" alt="mic"/>
                                            <div className="error-device">
                                                <PriorityHighIcon className="error-device-icon"></PriorityHighIcon>
                                            </div>
                                        </Icon>
                                        : isMicroOn
                                            ? <Icon handleOnclick={props.handleClickMicIcon}
                                                    titleTooltip={t("turn-off-the-mic")}>
                                                <img src="/img/ac-mic.svg" alt="mic"/>
                                            </Icon>
                                            : <Icon handleOnclick={props.handleClickMicIcon}
                                                    titleTooltip={t("turn-on-the-mic")} classCustom="bg-red">
                                                <img src="/img/ac-mic-mute.svg" alt="mic"/>
                                            </Icon>
                                }
                            </div>
                        </div>
                    }
                    {
                        connectType !== RECVONLY &&
                        <div className="container-setting-feature" hidden={connectType === RECVONLY}>
                            <div className="setting-feature">
                                {
                                    isShowSettingCameraQuickly
                                        ? <Icon titleTooltip={t("sound-setting-mode")} classCustom="bg-red"
                                                handleOnclick={handleShowSettingCameraQuickly}>
                                            <img src="/img/ci-arrow-down.svg" alt="camera"/>
                                        </Icon>
                                        : <Icon titleTooltip={t("sound-setting-mode")} classCustom="bg-red"
                                                handleOnclick={handleShowSettingCameraQuickly}>
                                            <img src="/img/ci-arrow-up.svg" alt="camera"/>
                                        </Icon>
                                }
                            </div>
                            <div>
                                {
                                    cameras.length === 0 || isErrorCamera
                                        ? <Icon handleOnclick={isErrorCamera ? handleOpenErrorCameraDialog : props.handleClickOpenErrorDevice}
                                              titleTooltip="Show more info"
                                              classCustom="bg-red">
                                            <img src="/img/ac-video.svg" alt="video"/>
                                            <div className="error-device">
                                                <PriorityHighIcon className="error-device-icon"></PriorityHighIcon>
                                            </div>
                                        </Icon>
                                        : isCameraOn
                                            ? <Icon handleOnclick={props.handleClickCameraIcon}
                                                    titleTooltip={t("turn-off-the-camera")}>
                                                <img src="/img/ac-video.svg" alt="video"/>
                                            </Icon>
                                            : <Icon handleOnclick={props.handleClickCameraIcon}
                                                    titleTooltip={t("turn-on-the-camera")} classCustom="bg-red">
                                                <img src="/img/ac-video-mute.svg" alt="video"/>
                                            </Icon>
                                }
                            </div>
                        </div>
                    }
                    <div hidden={!isMeeting || isMineShare || connectType === RECVONLY} className="hidden-mobile">
                        <Icon handleOnclick={() => {
                            props.shareScreen();
                            handleCloseSettingQuickly();
                        }} titleTooltip={t("share-screen")}>
                            <AcScreenSharing width="20px" color="#ffffff"/>
                        </Icon>
                    </div>
                    <div hidden={!isMeeting || !isMineShare || connectType === RECVONLY} className="hidden-mobile">
                        <Icon handleOnclick={() => {
                            props.stopShare();
                            handleCloseSettingQuickly();
                        }} titleTooltip={t("stop-screen-sharing")} classCustom="bg-blue">
                            <AcScreenSharing width="20px" color="#ffffff"/>
                        </Icon>
                    </div>
                    {
                        isMeeting &&
                        <Icon handleOnclick={() => {
                            handleShowChat();
                            handleCloseSettingQuickly();
                        }}
                              titleTooltip={isShowChat ? t("close-chat") : t("chat")}
                              classCustom={`hidden-mobile ${isShowChat ? "bg-blue" : ""}`}>
                            <img src="/img/ac-chat.svg" alt="chat"/>
                            {
                                !isSeenMessage &&
                                <div className="is-seen-message"></div>
                            }
                        </Icon>
                    }
                    <Icon handleOnclick={handleClickEmojiEl} classCustom="hidden-mobile" titleTooltip={t("Send a reaction")}>
                        <img style={{ width: 26 }} src="/img/sentiment_satisfied_alt.svg" alt="sentiment_satisfied_alt"/>
                    </Icon>
                    {
                        connectType !== RECVONLY &&
                        <Icon handleOnclick={() => {
                            dispatch(setSHowParticipantList(!isSHowParticipantList));
                            handleCloseSettingQuickly();
                        }}
                              titleTooltip={isSHowParticipantList ? t("close-participant-list") : t("participant-list")}
                              classCustom={`hidden-mobile ${isSHowParticipantList ? "bg-blue" : ""}`}>
                            <AcMemberOutline width="20px" color="#ffffff"/>
                        </Icon>
                    }
                    {
                        connectType !== RECVONLY &&
                        <Icon handleOnclick={() => {
                            dispatch(setShowVirtualBackgroundModal(!isShowVirtualBackgroundModal));
                            handleCloseSettingQuickly();
                        }}
                              classCustom="hidden-mobile"
                              titleTooltip={t("effect-settings")}>
                        <AcStarsOutline width="20px" color="#ffffff"/>
                        </Icon>
                    }
                    <div hidden={connectType === RECVONLY}>
                        <Icon handleOnclick={(event: React.MouseEvent<HTMLElement>) => { handleClickBtnOption(event); handleCloseSettingQuickly(); }} titleTooltip={t("option")}>
                            <img src="/img/ac-option.svg" alt="ac-option"/>
                        </Icon>
                        <StyledMenu
                            id="basic-menu"
                            anchorEl={btnOptionAnchorEl}
                            open={open}
                            onClose={handleCloseBtnOption}
                            MenuListProps={{
                                "aria-labelledby": "basic-button"
                            }}
                        >
                            <MenuItem onClick={handleOpenRoomDetailModal} disableRipple>
                                <InfoOutlinedIcon width="20px" style={{color: "#232323", marginRight: "10px"}} />
                                {t("Detailed settings for this Session")}
                            </MenuItem>
                            {
                                !isMineShare
                                ? <MenuItem className="show-mobile" onClick={() => { props.shareScreen(); handleCloseBtnOption(); }}>
                                        <AcScreenSharing color="#232323"/>{t("share-screen")}
                                    </MenuItem>
                                : <MenuItem className="show-mobile" onClick={() => { props.stopShare(); handleCloseBtnOption(); }}>
                                    <AcScreenSharing color="#232323"/>{t("stop-screen-sharing")}
                                </MenuItem>
                            }
                            <MenuItem onClick={() => { handleShowChat(); handleCloseBtnOption(); }} className="show-mobile">
                                <AcChat color="#232323"/>{isShowChat ? t("close-chat") : t("chat")}
                                {
                                    !isSeenMessage &&
                                    <div className="is-seen-message-mobile"></div>
                                }
                            </MenuItem>
                            <MenuItem onClick={() => { dispatch(setSHowParticipantList(!isSHowParticipantList)); handleCloseBtnOption(); }} className="show-mobile">
                                <AcMemberOutline color="#232323"/>{isSHowParticipantList ? t("close-participant-list") : t("participant-list")}
                            </MenuItem>
                            <MenuItem onClick={() => { dispatch(setShowVirtualBackgroundModal(!isShowVirtualBackgroundModal)); handleCloseBtnOption(); }} className="show-mobile">
                                <AcStarsOutline color="#232323"/>{t("effect-settings")}
                            </MenuItem>
                            <MenuItem onClick={handleOpenSettingDevices}>
                                <SettingsIcon width="20px" style={{color: "#232323", marginRight: "10px"}} />{t("setting")}
                            </MenuItem>
                            {
                                isSubTitle
                                ? <MenuItem onClick={handleOpenSubTitleDialog}>
                                        <SubTitleIcon width="20px" height="20px" color="#F25A5A"/>{t("sub-title")}
                                    </MenuItem>
                                : <MenuItem onClick={handleOpenSubTitleDialog}>
                                        <SubTitleIcon width="20px" height="20px"/>{t("sub-title")}
                                    </MenuItem>
                            }
                            {
                                (isMeeting && isMeetingOwner) &&
                                <MenuItem onClick={handleRecordClick} disableRipple>
                                    <RadioButtonCheckedIcon style={{ marginRight: "10px" }} className={`${isRecording ? "icon-recording" : ""}`}/>
                                    {t("record")}
                                </MenuItem>
                            }
                            <MenuItem onClick={handleClickEmojiEl} disableRipple className="show-mobile"
                                      selected={particles.isShow && particles.type === ParticlesType.EMOJI}>
                                <SentimentSatisfiedAltIcon style={{ marginRight: "10px" }}/>{t("Send a reaction")}
                            </MenuItem>
                            {
                                isMeetingOwner &&
                                <MenuItem onClick={() => { handleCloseBtnOption(); dispatch(setShowBreakoutRoom(!isShowBreakoutRoom)); }}>
                                    <SubRoomIcon color="#232323"/>{t("sub room")}
                                </MenuItem>
                            }
                        </StyledMenu>
                    </div>
                    <div hidden={!isMeeting}>
                        <Icon handleOnclick={() => { props.callEnd(); dispatch(setFirstLogin(false)); handleCloseSettingQuickly(); }}
                              titleTooltip={t("leave-call")} classCustom="bg-red">
                            <img src="/img/ac-phone.svg" alt="ac-option"/>
                        </Icon>
                    </div>
                </div>
                <div className="extra"></div>
            </div>
            <DeviceModal open={openSettingDevices} handleClose={handleCloseSettingDevices} cameras={cameras} micros={micros} speakers={speakers}
                         selectedValueCamera={selectedValueCamera} selectedValueMic={selectedValueMic} selectedValueSpeaker={selectedValueSpeaker}
                         handleChangeCamera={handleChangeCamera} handleChangeMic={handleChangeMic} handleChangeSpeaker={handleChangeSpeaker}
            ></DeviceModal>
            <DeviceErrorModal open={isOpenErrorDevices} handleClose={props.handleClickCloseErrorDevice}></DeviceErrorModal>
            {
                particles.isShow && <>
                    {
                        particles.type === ParticlesType.SNOW && <ParticlesContainer/>
                    }
                    {
                        particles.type === ParticlesType.FIREWORKS && <FireworksParticles/>
                    }
                    {
                        particles.type === ParticlesType.EMOJI && <EmojiContainer />
                    }
                </>
            }
            <RoomDetailModal isShow={isShowRoomDetailModal} handleClose={handleCloseRoomDetailModal}></RoomDetailModal>
            <RecordModal
                isShow={isShowRecordModal}
                handleClose={() => { setShowRecordModal(false); }}
                startRecording={startRecording} />
            <Popover
                open={openAnchorEmojiEl}
                onClose={handleCloseEmojiEl}
                anchorEl={anchorEmojiEl}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center"
                }}
                transformOrigin={{
                    vertical: "bottom",
                    horizontal: "center"
                }}
            >
                <div className="emoji-list">
                    {
                        emojiList.map((emoji, index) => {
                            return <img key={index} className="emoji-item" src={emoji.url} alt={emoji.name}
                                        onClick={() => { handleClickEmoji(emoji); }}
                            />;
                        })
                    }
                </div>
            </Popover>
            <SubTitleDialog
                open={isShowSubTitleDialog}
                handleClose={handleCLoseSubTitleDialog} />
        </>
    );
});

Features.displayName = "Features";
export default Features;
