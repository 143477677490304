import React from "react";
import "./subTitle.css";

interface SubTitleProps {
    name: string | null
    content: string | null
    isFinal: boolean
}
function SubTitle (prop: SubTitleProps) {
    return <div className="subtitle">
        <span className="subtitle-name">{prop.name}: </span>
        <span className={`subtitle-content ${prop.isFinal ? "is-final" : ""}`}>{prop.content}</span>
    </div>;
}

export default SubTitle;
