export const MICRO_ID = "micro_id";
export const CAMERA_ID = "camera_id";
export const SPEAKER_ID = "speaker_id";
export const IS_CAMERA = "is_camera";
export const IS_MIC = "is_mic";
export const IS_VIRTUAL = "is_virtual";
export const REPLACE_URL_TO_TAG_A = /https?:\/\/[^\s<>]+/g;
export const PATH_MEETING = "/:channelId";
export const PATH_FORUM = "/:type/:id";
export const PATH_ADMIN = "/settings/mychannel";
export const PATH_ADMIN_CHANNEL = "/admin/channel";
export const YOU_JP = "あなた";
export const AVATAR_DEFAULT = "/img/ac-user-removebg-preview.png";
export const REMOTE_CONNECTION_SHOW = 29;
export const MAX_REMOTE_CONNECTION_SHOW = 30;
export const CHANNEL_LIMIT = ["gem_test", "bbt_test"];
export const FORUM_TYPE = "forum";
export const COURSE_TYPE = "course";
export const MEETING_TYPE = "mtg";
export const MEETING_TYPE_VALUE = "meeting";
export const ROLE_CONNECT = "role";
export const SENDRECV = "sendrecv";
export const RECVONLY = "recvonly";
export const ONE_HUNDREDTH_TWO = 100 / 2;
export const ONE_HUNDREDTH_THIRD = 100 / 3;
export const ONE_HUNDREDTH_FOUR = 100 / 4;
export const ONE_HUNDREDTH_FIVE = 100 / 5;
export const ONE_HUNDREDTH_SIX = 100 / 6;
export const VIRTUAL_BACKGROUND_IMG_LG = "/virtual-background/img-lg";
export const VIRTUAL_BACKGROUND_IMG_SM = "/virtual-background/img-sm";
export const MESSAGE_NOT_ALLOWED = "Signaling failed. CloseEventCode:4490 CloseEventReason:'Access to the meeting is not allowed'";
export const MESSAGE_NOT_ALLOWED_OVER_30 = "Signaling failed. CloseEventCode:4490 CloseEventReason:'You could not enter the room because the number of people who can enter was exceeded'";
export const HTTP = "http://";
export const HTTPS = "https://";
export const PATH_RECORD = "/settings/record";

export const PATH_ACCESS_LOG = "/settings/access-log";
export const NETWORK_STATUS = new Map([[0, "very stable network"], [1, "stable network"], [2, "unstable network"], [3, "very unstable network"]]);
export const VIDEO_QUALITY_DEFAULT = 360;
export const BACKGROUND_UPLOAD_LIST = "backgroundUploadList";
export const ALLOW_ACCESS_TYPE = {
    not_allow: 0,
    allow: 1,
    owner_allow: 2,
    user_allow: 3
};
export const LOGIN_JOIN_MEETING = "loginJoinMeeting";

export const MAX_MANAGERS_ROOM = 10;
export const PATH_ADMINISTRATOR_SETUP = "/settings/administrator-setup";
export const IS_NOISE_SUPPRESSION = "is_noise_suppression";
export const MIRRORING_WEBCAM = "mirroring_webcam";
export const LIGHT_ADJUSTMENT = "light_adjustment";
export const SCREEN_SHARE_AUDIO = "screen_share_audio";
export const STRENGTH_LIGHT_ADJUSTMENT = "strength_light_adjustment";
