import "./subTitle.css";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormGroup,
    InputLabel,
    MenuItem,
    type SelectChangeEvent
} from "@mui/material";
import Icon from "../icon/icon";
import CloseIcon from "@mui/icons-material/Close";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import type {AppDispatch, RootState} from "../../store";
import {setIsSubTitle, setSubtitleLanguage, setSubtitleTranslation} from "../../services/sora/soraSlice";
import {useTranslation} from "react-i18next";
import axios from "axios";

interface Props {
    open: any
    handleClose: any
}

export const languages = [
    {name: "Japanese", code: "ja"},
    {name: "English", code: "en"},
    {name: "Bulgarian", code: "bg"},
    {name: "Catalan", code: "ca"},
    {name: "Chinese (Mandarin, Simplified)", code: "zh"},
    {name: "Chinese (Mandarin, Traditional)", code: "zh-TW"},
    {name: "Chinese (Cantonese, Traditional)", code: "zh-HK"},
    {name: "Czech", code: "cs"},
    {name: "Danish", code: "da"},
    {name: "Danish", code: "da"},
    {name: "Estonian", code: "et"},
    {name: "Finnish", code: "fi"},
    {name: "Flemish", code: "nl-BE"},
    {name: "French", code: "fr"},
    {name: "German", code: "de"},
    {name: "German (Switzerland)", code: "de-CH"},
    {name: "Greek", code: "el"},
    {name: "Hindi", code: "hi"},
    {name: "Hungarian", code: "hu"},
    {name: "Indonesian", code: "id"},
    {name: "Italian", code: "it"},
    {name: "Korean", code: "ko"},
    {name: "Latvian", code: "lv"},
    {name: "Lithuanian", code: "lt"},
    {name: "Malay", code: "ms"},
    {name: "Multilingual (Spanish + English)", code: "multi"},
    {name: "Norwegian", code: "no"},
    {name: "Polish", code: "pl"},
    {name: "Portuguese", code: "pt"},
    {name: "Romanian", code: "ro"},
    {name: "Russian", code: "ru"},
    {name: "Slovak", code: "sk"},
    {name: "Spanish", code: "es"},
    {name: "Swedish", code: "sv"},
    {name: "Thai", code: "th"},
    {name: "Turkish", code: "tr"},
    {name: "Ukrainian", code: "uk"},
    {name: "Vietnamese", code: "vi"}
];

export default function SubTitleDialog (props: Props) {
    const {channelId, isSubtitleTranslation, subtitleLanguage} = useSelector((state: RootState) => state.sora);
    const [language, setLanguage] = React.useState(subtitleLanguage);
    const dispatch = useDispatch<AppDispatch>();
    const { t } = useTranslation();

    const handleChangeLanguage = (event: SelectChangeEvent) => {
        setLanguage(event.target.value);
    };

    const handleSubtitleTranslation = (event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(setSubtitleTranslation(event.target.checked));
    };

    const handleUpdateSubtitleSetting = () => {
        if (subtitleLanguage !== language) {
            dispatch(setSubtitleLanguage(language));
        }
        handleStartSubtitle();
        props.handleClose();
    };

    const handleStartSubtitle = () => {
        const data = {
            channel_id: channelId
        };

        axios.post(`${process.env.REACT_APP_WEBRTC_API || ""}`, data, {
            headers: {
                "x-sora-target": "Sora_20221221.StartAudioStreaming"
            }
        }).then(() => {
            dispatch(setIsSubTitle(true));
        }).catch((error) => {
            if (error.response.data.error_type === "STARTED-AUDIO-STREAMING") {
                dispatch(setIsSubTitle(true));
            }
        });
    };

    const handleStopSubtitle = () => {
        dispatch(setIsSubTitle(false));
        props.handleClose();
    };

    return <Dialog open={props.open} onClose={props.handleClose} className="customized-dialog" fullWidth>
        <DialogTitle>{t("Subtitle setting")}
            <Icon handleOnclick={props.handleClose}>
                <CloseIcon className="icon-features" sx={{fontSize: 30, color: "#000"}}/>
            </Icon>
        </DialogTitle>
        <DialogContent>
            <FormControl fullWidth margin="dense">
                <InputLabel id="demo-simple-select-label">{t("Language")}</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Language"
                    value={language}
                    onChange={handleChangeLanguage}
                >
                    {
                        languages.map((language, index) => {
                            return <MenuItem value={language.code} key={index}>{language.name}</MenuItem>;
                        })
                    }
                </Select>
            </FormControl>
            <FormGroup>
                <FormControlLabel control={<Checkbox checked={isSubtitleTranslation} onChange={handleSubtitleTranslation} />} label={t("Subtitle translation")} />
            </FormGroup>
        </DialogContent>
        <DialogActions>
            <Button type="submit" onClick={handleStopSubtitle}>{t("Hide")}</Button>
            <Button type="submit" onClick={handleUpdateSubtitleSetting}>{t("Show")}</Button>
        </DialogActions>
    </Dialog>;
}
